export const environment = {
  production: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    facebook: {
      name: "Facebook",
      API_ENDPOINT: "https://metaapi-backend.cifapps.com/",
      default: true,
      Bearer:   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQ0MTRmNzdmNWYzYjJjZTMxMDE1OWM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ0MTRmYTM3M2IyYmNmNjc4MDIwNGMyIn0.AhyDdpBlmTaDZFZTrurAKBWHERRR4SuIiQeRi5JlVREaKAZ0VZT2B-CHTXDcU5IS6-BdvbDTCJ8vidj37sVuSE0gCwlBjxHoWbXgVk6FuU2XQqeecFWFmYnu9ITJk-cI3HuNZ5Dypiy2o2uHPY8yK2PcIT4-A4x61W0oypaIsmFKBF8pkCFVfqs0FwbSua7469e1zG4RNJpxmd1dkbAWzVa95zJaxw2gJfaTB8KzPK5FGl6gZans48iiqo5m7Rl_6v3aPuOZNKqYjz6o-yR16jlamZxA8XTABZJBUtCH3fiOQH-jWSXfU5of571Biih_7XCsnCTKhVMRHTomSMtKow",
    },
  },
};
/* Bearer is App token for server */
